<template>
  <div>
    <EkomiSeal />
    <a
      href="https://www.trustedshops.de/bewertung/info_XD28B09EDC76335A47FB54A25E74BA82B.html"
      target="_blank"
      class="badge-ts"
      v-if="storeView.storeCode == 'zaun24_de'"
    >
      <img
        v-lazy="'/assets/logo/logo-trustedshops.svg'"
        alt="Trusted Shops"
        width="70px"
        height="70px"
      >
    </a>
    <router-link
      :to="localizedRoute('tuev-zertifizierung')"
      class="badge-tuev"
    >
      <img
        v-lazy="'/assets/logo/seal-SC45376-decoga-gmbh-tuev-gepruefter-online-shop-2023-zw.svg'"
        alt="TÜV Saarland - geprüfter Onlineshop"
        width="150px"
        height="80px"
      >
    </router-link>
  </div>
</template>

<script>
import { currentStoreView, localizedRoute } from '@vue-storefront/core/lib/multistore'
import EkomiSeal from '../Custom/EkomiSeal'

export default {
  name: 'FooterTrustSeals',
  components: {
    EkomiSeal
  },
  computed: {
    storeView () {
      return currentStoreView()
    }
  }
}
</script>
